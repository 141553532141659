// Escrow list
export default Object.freeze({
  "0x2D407dDb06311396fE14D4b49da5F0471447d45C": {
    ESCROW: "0xB1A1Ae5c34CEec969E1f7e176fE8A0506CE044D6",
  },
  "0x22357E908CFbc20b9F1eBa8b0064CA38b6c6C116": {
    ESCROW: "0xA8823DC09D486718E8e2EEb9B82dE4d5d755f1Ef",
  },
  "0x15F18E85DB9f415811B7678C7bbB3fd4E716b648": {
    ESCROW: "0x3EB2E01E4a6d1BE679Ab5bD73c3d6Ddb568E9cDe",
  },
  "0x0937239d7daa7e9317ceAfD24B7f7d5B26996871": {
    ESCROW: "0x68C97d275F2E73e7059ffC191C16F660B88f6370",
  },
  "0x5bA6624Ed97EAdDC2a2b5778A2771716Eb4Ca96A": {
    ESCROW: "0xF4EFDc3416BdcF258c157e95b8b3EF800C5c93F9",
  },
  "0x7C5D550fbB1A3B37e4e893c1B1673eaD320DC548": {
    ESCROW: "0x102af6aA34c17b9377447aB91E73AcBEb76B8501",
  },
  "0xffFfA7D95ca2323d6bF4e46C5A287BF11219172E": {
    ESCROW: "0x42A28ADDC15E627d19e780c89043b4B1d3629D34",
  },
  "0xa590a1cD83Cd8B51Abd58F9A874Daa67F1162D9B": {
    ESCROW: "0x63c61febe1647Ed9a66f0b1f801F90DCaF5C7aC3",
  },
  "0x40e57bE7D6c91A2DE8DCd63198f574581f8Fda2a": {
    ESCROW: "0xd1002D0F89DFCCc662B9B63E3Eb0AB2d4c858Ed8",
  },
  "0x163F0Aa0B7DA778953C1DE995D849a826FE0c0A4": {
    ESCROW: "0x0AcB5b3EeADD78bF6aCec49223B89b049013a23B",
  },
  "0xbA9ad40dA02620144c15Bcca98E836e75e912906": {
    ESCROW: "0x771Daf7c204FDf355d0F835c6d990ADD8931dBc9",
  },
  "0x9472d9f14200186A086Fc2Df53CAA82dfBDd9bD8": {
    ESCROW: "0x0e1F845736baF6B8E44432B88191F7F5a3FCD679",
  },
  "0x2839df1F230dedA9fDDBF1BCB0D4eB1Ee1f7b7d0": {
    ESCROW: "0x890A84ab4f739D7D7390ea6b995eb5f959f2E1AB",
  },
  "0xB0fc0776cEd4A6C2e39c080279040a6EEA303B57": {
    ESCROW: "0x2BBa2D2986FF4a987eDe14113845B38a9Ea748De",
  },
  "0xbF6bfA22e88bFEddaa6d0c5C64f0a91a775D6DB3": {
    ESCROW: "0x0FEdf37A51582CB3C4545Ff416c6D671D7C5981f",
  },
  "0x90e5aa59a9dF2ADd394df81521DbBEd5F3c4A1A3": {
    ESCROW: "0x8bc89738bCf37d43B05ad47079332e3aD2B82C4F",
  },
  "0xf3B2cDBF23A7F42BEDa882cb477FD8a7103186d7": {
    ESCROW: "0x5dAb4Cb743CD55383ABBF64753Df0573d4E010ec",
  },
  "0x3F37a30cEa56C5d199cEB4250354FDCd84805538": {
    ESCROW: "0xF9e828098539d1740ea88D7Be2d125A14e2840e7",
  },
  "0xCdAA49f08e612357347b25F323529444Fc1EBC66": {
    ESCROW: "0x5eaCeEEa48D5f69f517396F03803b28E42e9de80",
  },
  "0x9A4a20fC422F849cAA37Fd32A873223e23077317": {
    ESCROW: "0x363c2A399646dDbE2f0918D1eBFaDcf024b268C0",
  },
  "0x77472E7Be6B27FED4367A2D626AF2B94775EbD27": {
    ESCROW: "0x4FB3151D1855E2052eb442084fB104770702df10",
  },
  "0x4eE93A529d6C3Ed014dA4bC10106a61ed666c820": {
    ESCROW: "0x4B05f2731B40753dAefd7b4bfC3Ec725190C3453",
  },
  "0xf1AB0e37475F949b16D70f09ceF672A54019B053": {
    ESCROW: "0xc6a3F12ba5a35aa019A5681d59A3f063bCA73c31",
  },
  "0x5b607d28180F7260c6726048E909CB8f1A271CE0": {
    ESCROW: "0x2a2B5E9996DeD658eCcEa50680090d6E9C20499a",
  },
  "0x7F021632F3741e1b22126ecB7F100046Cc9038DF": {
    ESCROW: "0x13fCc130f89B4c048527D375Cad9AAf6dc0Fcd55",
  },
  "0x14fEE59eAF210DA119926850036A2791a3674A2a": {
    ESCROW: "0xb91148e9b89fFD4d70aAf61E37a0c0FDB7956A11",
  },
  "0xE18B7aE9ABbeDad231AD23D10baf6a4365AfC8fA": {
    ESCROW: "0x9160F5c55f943896A827cF93F16b06B7D78b5473",
  },
  "0xB0Dab85eb5896Fa249EB448EE692D6d636C14C95": {
    ESCROW: "0x8C71522fB660F0627Fe522bDFAd0530bD3588BB2",
  },
  "0xC983E2CcF24Ed6a818Abb1E3Dd2cb53b565afD44": {
    ESCROW: "0x908FE98F12e231f646dD8E0889FcD76E6Bd0E56c",
  },
  "0x25F8787A42b06Ce8CFCA2dF4a169a220147b5eFc": {
    ESCROW: "0x8651678562811fd1fCDA3315DCBF24AbFc70A0b4",
  },
  "0xb512bfb6343b09c94ab48627caebf63009d7cfd2": {
    ESCROW: "0xe0a5a6bd150eb8813f40c83511a9f7110c86f588",
  },
  "0x02C1dEa7485423C9C9eB8C730C2aac56cfF74aDF": {
    ESCROW: "0xaA18a25d7c169f27346B335495F1BE6A13056674",
  },
  "0x1E808dCE271eE5096614f3d34068F912C6459E7f": {
    ESCROW: "0x9BeFF9095D9dE093DbA704f0EB78FBF0fE53a29A",
  },
  "0xc16376B5c812b3301fed4CeCecF122D9366f20f6": {
    ESCROW: "0xd3e580e9E15B454C8226C1A40f41C5E4a155d3Bc",
  },
  "0xfDB330A6E993Ce4BBA457687807c6eE6C650722C": {
    ESCROW: "0xfF14a98e8aE247e3DB5ff7b6BFe6c01779f0E602",
  },
  "0xB3E08599AC57666BE68dBB3D311B9C607900A83b": {
    ESCROW: "0xDE0Ae8a47fF8e2Ca0fE0DD864772767851A1d5d1",
  },
  "0x77472E7Be6B27FED4367A2D626AF2B94775EbD27": {
    ESCROW: "0x4FB3151D1855E2052eb442084fB104770702df10",
  },
  "0x7F021632F3741e1b22126ecB7F100046Cc9038DF": {
    ESCROW: "0xbB0f317A38b3ED8842BC025ed29772969a4881C0",
  },
  "0x5b607d28180F7260c6726048E909CB8f1A271CE0": {
    ESCROW: "0x8Ae17725Ba99A5790EDEa2c9a457996A0897b49a",
  },
  "0x78e3F488b0655c9F7310065765D91dC3812bD87f": {
    ESCROW: "0xF560caaf80F6De7329F1964729ed1cfA376e8ecF",
  },
  "0x355e8571034aB13663232750D3F908De953Fa1F3": {
    ESCROW: "0xEaE3c9bAC391173B5BEF84AF0DA73784BE336462",
  },
  "0xe219B069D0c35186Dc97cB550e5Dc3CE863aA766": {
    ESCROW: "0xF4A5A658fdAfa4eb670d58E021371DDE9e647013",
  },
  "0xaf02cD3F19316d7c8a96987fd056931cB125494c": {
    ESCROW: "0xE0759fd6a34C4e361E1BA274C04cb5e6c1E370eC",
  },
  "0x2610650e5D4Db574bcD3505d2163991eCA85A0d9": {
    ESCROW: "0xe2dFFD5E6BC5000f125e264e69A7d12f4b332D68",
  },
});
